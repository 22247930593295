var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataQuestionCBTStudent.mata_pelajaran || _vm.isTeacher)?_c('div',{staticClass:"examCBT"},[(!_vm.isTeacher)?_c('div',{staticClass:"countdownTimer"},[_c('h4',[_vm._v("TIME LEFT")]),_c('h2',[_vm._v(_vm._s(_vm.formattedDuration))])]):_vm._e(),_c('a-modal',{attrs:{"visible":_vm.modalSubmit,"ok-text":"Submit","cancelButtonProps":{
      props: {
        type: 'danger',
      },
    },"cancel-text":"No, I still want to re-check my answer","confirm-loading":_vm.loadingSubmit},on:{"cancel":_vm.cancelModal,"ok":_vm.submitAnswer}},[_c('h3',{staticStyle:{"line-height":"1.7rem"},attrs:{"slot":"title"},slot:"title"},[_vm._v(" Are you sure to submit your answer? ")]),_c('div',[_c('div',[_vm._v("Your answer "),_c('b',[_vm._v("could not be changed")]),_vm._v(" once you submit.")]),_c('ul',[_c('li',[_vm._v(" Answered: "),_c('b',[_vm._v(_vm._s(_vm.stats.answered))])]),_c('li',{staticClass:"text-danger"},[_vm._v(" Not Answered: "+_vm._s(_vm.totalQuestion.length - _vm.stats.answered)+" ")])])])]),_c('div',{staticClass:"descStartExam"},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[(!_vm.isTeacher)?[_c('h2',{staticClass:"mb-5"},[_vm._v(" Subject: "+_vm._s(_vm.dataQuestionCBTStudent.mata_pelajaran.nama)+" ")])]:[_c('h2',[_vm._v("QUESTION PREVIEW")]),_c('a-button',{staticClass:"mb-5",on:{"click":function($event){$event.preventDefault();return _vm.backDetail.apply(null, arguments)}}},[_vm._v("Back to Detail CBT")])]],2)],1)],1),_c('div',{staticClass:"allExamNumber"},_vm._l((_vm.totalQuestion),function(data){return _c('div',{key:data.no,staticClass:"examNumber"},[_c('a-button',{style:({
          backgroundColor: _vm.colorToggle(data).backgroundColor,
          color: _vm.colorToggle(data).color,
        }),on:{"click":function($event){$event.preventDefault();return _vm.changeCurrentNumber(data.no)}}},[_vm._v(" "+_vm._s(data.no)+" ")])],1)}),0),_vm._l((_vm.totalQuestion),function(data,i){return [(_vm.currentNumber === data.no && data)?_c('div',{key:data.no,staticClass:"soalCBT"},[_c('SoalExamCBT',{attrs:{"dataSoal":data,"no":data.no,"isTeacher":_vm.isTeacher},on:{"goToNext":function (payload) { return _vm.updateJawaban(payload, i); }}})],1):_vm._e()]}),_c('div',{staticClass:"d-md-flex justify-content-center"},[_c('div',{staticClass:"mb-3 mb-md-0 mr-md-3 text-center"},[_c('a-button',{attrs:{"type":"primary","disabled":_vm.currentNumber === 1},on:{"click":function($event){$event.preventDefault();return _vm.clickPrevious.apply(null, arguments)}}},[_vm._v("< Previous Question")])],1),_c('div',{staticClass:"mb-3 mb-md-0 mr-md-3 text-center"},[_c('a-button',{staticClass:"notSureBtn",staticStyle:{"background-color":"#ff8811","color":"white"},attrs:{"disabled":_vm.totalQuestion[_vm.currentNumber - 1] &&
          !_vm.totalQuestion[_vm.currentNumber - 1].jawaban},on:{"click":function($event){$event.preventDefault();return _vm.changeStatusUnsure.apply(null, arguments)}}},[_vm._v("Not Sure Yet")])],1),_c('div',{staticClass:"text-center"},[(_vm.currentNumber !== _vm.totalQuestion.length)?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.clickNext.apply(null, arguments)}}},[_vm._v("Next Question >")]):_c('a-button',{style:({
          backgroundColor: _vm.isTeacher ? '' : '#1EBC61',
          color: _vm.isTeacher ? '' : '#ffffff',
        }),attrs:{"disabled":_vm.isTeacher},on:{"click":function($event){$event.preventDefault();_vm.modalSubmit = true}}},[_vm._v("Submit Your Answer")])],1)]),_c('div',[_c('a-divider',{attrs:{"orientation":"left"}},[_c('h2',[_vm._v("Status")])]),_c('a-row',{staticClass:"statusQuestion",attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"xs":24,"lg":8}},[_c('a-card',{staticClass:"cardOverall"},[_c('span',{staticStyle:{"color":"#41b883"},attrs:{"slot":"title"},slot:"title"},[_vm._v("Answered")]),_c('div',{staticClass:"centeringBody"},[_c('div',{staticClass:"scoreStatus",staticStyle:{"color":"#41b883"}},[_vm._v(" "+_vm._s(_vm.stats.answered)+" ")]),_c('div',{staticClass:"additionalText"},[_vm._v("question")])])])],1),_c('a-col',{attrs:{"xs":24,"lg":8}},[_c('a-card',{staticClass:"cardOverall"},[_c('span',{staticStyle:{"color":"#ff8811"},attrs:{"slot":"title"},slot:"title"},[_vm._v("Not Sure")]),_c('div',{staticClass:"centeringBody"},[_c('div',{staticClass:"scoreStatus",staticStyle:{"color":"#ff8811"}},[_vm._v(" "+_vm._s(_vm.stats.notSureYet)+" ")]),_c('div',{staticClass:"additionalText"},[_vm._v("question")])])])],1),_c('a-col',{attrs:{"xs":24,"lg":8}},[_c('a-card',{staticClass:"cardOverall"},[_c('span',{staticStyle:{"color":"#ff0000"},attrs:{"slot":"title"},slot:"title"},[_vm._v("Not Answered")]),_c('div',{staticClass:"centeringBody"},[_c('div',{staticClass:"scoreStatus",staticStyle:{"color":"#ff0000"}},[_vm._v(" "+_vm._s(_vm.totalQuestion.length - _vm.stats.answered)+" ")]),_c('div',{staticClass:"additionalText"},[_vm._v("question")])])])],1)],1)],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }